import axios from '../axios.instance'

export default class BookingService {

    //================BOOKINGS================
    async findBookingById(bookingid) {
        try {
            if(bookingid != 0) {
                const res = await axios.get('/booking/findbyid/', {params: {id: bookingid}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async getBookingData() {
        try {
            const res = await axios.get('/booking/getbookingdata/')
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addBooking(booking_info, booking_arrivalinfo, booking_departinfo, booking_landingarea_info) {
        try {
            if(booking_info != ''){
                const res = await axios.post('/booking', {booking_info, booking_arrivalinfo, booking_departinfo, booking_landingarea_info})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateBooking(bookingID, booking_info, booking_arrivalinfo, booking_departinfo, booking_landingarea_info) {
        try {
            if(bookingID != 0){
                const res = await axios.put('/booking/'+bookingID, {booking_info, booking_arrivalinfo, booking_departinfo, booking_landingarea_info})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteBooking(booking_id){
        try {
            if(booking_id != 0) {
                const res = await axios.delete('/booking/'+booking_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }


    async complaintTourCode(data) {
        try {
            if(data) {
                const res = await axios.get('/booking/complainttourcode/', {params: {tourcode: data}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async searchByTourCode(data) {
        try {
            if(data) {
                const res = await axios.get('/booking/searchbytourcode/', {params: {tourcode: data}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async filterBooking(params) {
        try {
            if(params != {}) {
                const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
                const res = await axios.get('/booking/filterbooking/?'+queryParams)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //================BOOKING COMPANY================
    async getBookingCompany(){
        try {
            const res = await axios.get('/bookingcompany')
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addBookingCompany(data) {
        try {
            if(data != ''){
                const res = await axios.post('/bookingcompany', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateBookingCompany(id, data) {
        try {
            if(data != ''){
                const res = await axios.put('/bookingcompany/'+id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteBookingCompany(id) {
        try {
            if(id != 0) {
                const res = await axios.delete('/bookingcompany/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //================ARRIVAL INFO================
    async getArrivalInfo(id){
        try {
            if(id) {
                const res = await axios.get('/booking/getarrivalinfo/', {params: {bookingid: id}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //================ITINERARY INFO================
    async getItineraryData(id){
        try {
            if(id) {
                const res = await axios.get('/booking/getitinerary/', {params: {bookingid: id}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //================BOOKING HOTEL================
    async getBookingHotel(id){
        try {
            if(id != 0) {
                const res = await axios.get('/bookinghotel/', {params: {bookingid: id}})
                return res.data;
            }

            // //USE TO GET DATA WHEN PRINTING HOTEL VOUCHER
            // if(param.bookinghotelid) {
            //     const res = await axios.get('/bookinghotel/', {params: {bookinghotelid: param.bookinghotelid}})
            //     return res.data;
            // }
        } catch (error) {
            return error.response.data
        }
    }

    async printHotelVoucher(id){
        try {
            //USE TO GET DATA WHEN PRINTING HOTEL VOUCHER
            if(id != 0) {
                const res = await axios.get('/bookinghotel/printvoucher', {params: {bookinghotelid: id}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async addBookingHotel(data) {
        try {
            if(data != ''){
                const res = await axios.post('/bookinghotel', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateBookingHotel(id, data) {
        try {
            if(id != 0){
                const res = await axios.put('/bookinghotel/'+id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteBookingHotel(id) {
        try {
            if(id != 0) {
                const res = await axios.delete('/bookinghotel/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //================BOOKING RESTAURANT================
    async getBookingRestaurant(id){
        try {
            if(id != 0) {
                const res = await axios.get('/bookingrestaurant/', {params: {bookingid: id}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async printRestaurantVoucher(id){
        try {
            //USE TO GET DATA WHEN PRINTING HOTEL VOUCHER
            if(id != 0) {
                const res = await axios.get('/bookingrestaurant/printvoucher', {params: {bookingrestaurantid: id}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async addBookingRestaurant(data) {
        try {
            if(data != ''){
                const res = await axios.post('/bookingrestaurant', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateBookingRestaurant(id, data) {
        try {
            if(id != 0){
                const res = await axios.put('/bookingrestaurant/'+id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteBookingRestaurant(id) {
        try {
            if(id != 0) {
                const res = await axios.delete('/bookingrestaurant/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //================BOOKING TOUR GUIDE================
    async getBookingTourGuide(id){
        try {
            if(id != 0) {
                const res = await axios.get('/bookingtourguide/', {params: {bookingid: id}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async addBookingTourGuide(data) {
        try {
            if(data != ''){
                const res = await axios.post('/bookingtourguide', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateBookingTourGuide(id, data) {
        try {
            if(id != 0){
                const res = await axios.put('/bookingtourguide/'+id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteBookingTourGuide(id) {
        try {
            if(id != 0) {
                const res = await axios.delete('/bookingtourguide/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //================BOOKING TRANSPORT================
    async getBookingTransport(id){
        try {
            if(id != 0) {
                const res = await axios.get('/bookingtransport/', {params: {bookingid: id}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async addBookingTransport(data) {
        try {
            if(data != ''){
                const res = await axios.post('/bookingtransport', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateBookingTransport(id, data) {
        try {
            if(id != 0){
                const res = await axios.put('/bookingtransport/'+id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteBookingTransport(id) {
        try {
            if(id != 0) {
                const res = await axios.delete('/bookingtransport/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //================BOOKING OPTIONAL TOUR================
    async getOptionalTourBooking(id){
        try {
            if(id != 0) {
                const res = await axios.get('/bookingoptionaltour/', {params: {bookingid: id}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async addBookingOptionalTour(data) {
        try {
            if(data != ''){
                const res = await axios.post('/bookingoptionaltour', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateBookingOptionalTour(id, data) {
        try {
            if(id != 0){
                const res = await axios.put('/bookingoptionaltour/'+id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteBookingOptionalTour(id) {
        try {
            if(id != 0) {
                const res = await axios.delete('/bookingoptionaltour/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}